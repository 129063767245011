import { inject } from 'vue';
import User from '@/common/api/service/user';
import * as UserModel from '@/common/api/model/user';
import Storage from '@/utils/storage';
// import { LoginResult } from '@/views/login/model/LoginModel';

export default function useSubAccount() {
  const $store: any = inject('$store');

  function subAccountFn(params: UserModel.ApiPostUserSwitchAccountModel) {
    return User.apiPostUserSwitchAccount(params).then(async (res: any) => {
      const result = res.result;
      Storage.set('userData', result);
      await $store.dispatch('initData');
      await $store.dispatch('apiGetRegStatusFn');
      await $store.dispatch('apiGetCartCountFn');
    });
  }
  return {
    subAccountFn,
  };
}
