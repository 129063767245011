import PageLayout from '@/layouts/PageLayout.vue';
const user2 = {
  path: 'user2',
  redirect: '/user/sbindex',
  name: ' 我的青牛',
  meta: { name: ' 我的青牛' },
  component: PageLayout,
  children: [
    {
      path: 'nblog',
      name: '牛币明细',
      component: () => import(/* webpackChunkName: "user2" */ '../views/user/NbLog.vue'),
      meta: { name: '牛币明细' },
    },
    {
      path: 'moneylog',
      name: '账单明细',
      component: () => import(/* webpackChunkName: "user2" */ '../views/user/MoneyLog.vue'),
      meta: { name: '账单明细', background: '#fff' },
    },
  ],
};
export default user2;
