
  /**
   * 分类菜单组件
   */
  import { defineComponent, ref, unref, onMounted, inject } from 'vue';
  import Product from '@/common/api/service/product';
  import { CatItemModel } from '@/@types/index';

  export default defineComponent({
    name: 'CategoryMenu',
    setup() {
      const $route: any = inject('$route');

      //是否显示二级分类
      const isCarousel = ref(false);
      const CarouselRef = ref();
      let categorys: any = ref([]);
      let catMap: any = ref({});

      //移入
      function mouseover(index: number | string) {
        if (index !== 0) {
          isCarousel.value = true;
          if (unref(CarouselRef)) {
            CarouselRef.value.setActiveItem(index);
          }
        } else {
          isCarousel.value = false;
        }
      }
      //移出
      function mouseleave() {
        isCarousel.value = false;
      }

      async function init() {
        let res = await Product.apiGoodsCat();
        let catNameList: CatItemModel[] = [
          {
            catName: '全部分类',
            catId: 0,
            catImg:
              'https://qnyy.oss-cn-hangzhou.aliyuncs.com/uplaod/goodscats/20201221/fe65e1749d9677820810454e3631911f1607010d.png?x-oss-process=image/resize,m_fill,h_300,w_300',
          },
        ];
        let map: any = {};
        for (let i = 0; i < res.result.length; i++) {
          let item: CatItemModel = res.result[i];
          catNameList.push({
            catId: item.catId,
            catImg: item.catImg,
            catName: item.catName,
          });
          map[item.catName] = item.children || [];
        }
        categorys.value = catNameList;
        catMap.value = map;
      }

      function toGoodsList(arg: CatItemModel) {
        if (!arg.catId) {
          $route.push('/product/quick/?type=all');
          return;
        }
        $route.push(`/product/search?catId=${arg.catId}&catName=${arg.catName}`);
      }

      onMounted(() => {
        init();
      });

      return {
        categorys,
        isCarousel,
        catMap,
        CarouselRef,
        mouseover,
        mouseleave,
        toGoodsList,
      };
    },
  });
