export enum PAY_ITEM_ENUM {
  Wechat = '微信支付',
  Alipay = '支付宝',
  B2B = 'B2B支付',
  Wallet = '余额支付',
	MixPay = '混合支付',
}

export enum TOP_TABBAR_ENUM {
  Index = 'index',
  Hot = 'hot',
  Discount = 'discount',
  New = 'new',
  Quick = 'quick',
  NBShop = 'nb_shop',
}
