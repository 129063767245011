import axios from '../axios';

export default class Index {
  /**
   * 获取首页轮播图
   * @returns
   */
  public static async getBannerList(): Promise<any> {
    return await axios({
      method: 'get',
      url: '/api_v2/advs',
      params: {
        positionCode: 'img-index',
        num: '10',
        type: 1,
      },
    });
  }
  /**
   * 获取限时特价
   * @returns
   */
  public static async getDiscounGoods(): Promise<any> {
    return await axios({
      method: 'get',
      url: '/api_v2/get_discount_goods',
    });
  }
  /**
   * 获取首页配置
   * @returns
   */
  public static async getIndexData(): Promise<any> {
    return await axios({
      method: 'get',
      url: '/api_v2/index_pc',
    });
  }

  /**
   *
   * @returns 是否显示通告
   */
  public static async index_show_notice(): Promise<any> {
    return await axios({
      method: 'get',
      url: '/api_v2/index_show_notice',
    });
  }

  /**
   *
   * @returns 同意转新仓
   *
   */

  public static async index_agree(): Promise<any> {
    return await axios({
      method: 'post',
      url: '/api_v2/index_agree',
    });
  }

	/**
   *
   * @returns 获取停服时间
   */
	public static async getStopIndex(): Promise<any> {
		return await axios({
			method: 'get',
			url: '/api_v2/stop_index',
		});
	}
}
