import PageLayout from '@/layouts/PageLayout.vue';
const help = {
  path: 'helpCenter',
  redirect: '/helpCenter',
  component: PageLayout,
  children: [
    {
      path: '',
      name: '帮助中心',
      component: () => import(/* webpackChunkName: "helpCenter" */ '../views/help/HelpCenter.vue'),
      meta: { name: '帮助中心' },
    },
  ],
};
export default help;
