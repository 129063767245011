import axios from '../axios';
import * as ProductModel from '../model/product';

export default class Product {
  /**
   * 获取快速下单产品
   * @param url
   * @returns
   */
  public static async getQuickProductList(
    url: string,
    params: ProductModel.QuickProductModel
  ): Promise<any> {
    return await axios({
      method: 'get',
      url: '/api_v2' + url,
      params,
    });
  }

  /**
   * 获取商品列表
   * @param url
   * @returns
   */
  public static async apiSearchGoods(
    params: ProductModel.ApiSearchGoodsModel,
    option: any = {}
  ): Promise<any> {
    return await axios({
      method: 'get',
      url: '/api_v2/search_goods',
      params,
      ...option,
    });
  }

  /**
   * 获取商品分类
   * @param url
   * @returns
   */
  public static async apiGoodsCat(params: any = {}, option: any = {}): Promise<any> {
    return await axios({
      method: 'get',
      url: '/api_v2/goods_cat',
      params,
      ...option,
    });
  }

  /**
   * 添加购物车
   * @param data
   * @returns
   */
  public static async addCart(
    data: ProductModel.AddCartProductModel,
    option: any = {}
  ): Promise<any> {
    return await axios({
      method: 'post',
      url: '/api_v2/add_cart',
      data,
      ...option,
    });
  }
  /**
   * 添加牛币购物车
   * @param data
   * @returns
   */
  public static async apiPostAddIntegralCart(
    data: ProductModel.ApiPostAddIntegralCartModel,
    option: any = {}
  ): Promise<any> {
    return await axios({
      method: 'post',
      url: '/api_v2/add_integral_cart',
      data,
      ...option,
    });
  }

  /**
   * 牛币商城
   */
  public static async getNbProduct(): Promise<any> {
    return await axios({
      method: 'get',
      url: '/api_v2/integral_page_info?type=2',
    });
  }
  /**
   * 获取牛币列表
   * @param params
   * @returns
   */
  public static async getNbProductList(params: ProductModel.NbProductModel): Promise<any> {
    return await axios({
      method: 'get',
      url: '/api_v2/integral_list',
      params,
    });
  }

  /**
   * 获取商品详情
   * @param params
   * @param option
   * @returns
   */
  public static async getGoodsDetail(
    params: ProductModel.GetGoodsDetailModel,
    option: any = {}
  ): Promise<any> {
    return await axios({
      method: 'get',
      url: '/api_v2/goods_detail',
      params,
      ...option,
    });
  }

  /**
   * 收藏商品
   * @param params
   * @param option
   * @returns
   */
  public static async apiFavoriteCollect(
    params: ProductModel.PostFavoriteCollectModel,
    option: any = {}
  ): Promise<any> {
    return await axios({
      method: 'post',
      url: '/api_v2/favorite_collect',
      params,
      ...option,
    });
  }

  /**
   * 牛币商品详情
   * @param params
   * @param option
   * @returns
   */
  public static async apiGetIntegralGoodsDetail(
    params: ProductModel.ApiGetIntegralGoodsDetailModel,
    option: any = {}
  ): Promise<any> {
    return await axios({
      method: 'get',
      url: '/api_v2/integral_goods_detail',
      params,
      ...option,
    });
  }

  // 即将到货
  public static async apiGetGoodsComing(params: any = {}, option: any = {}): Promise<any> {
    return await axios({
      method: 'get',
      url: '/api_v2/goods_coming',
      params,
      ...option,
    });
  }

  // 获取搜索联想词组
  public static async apiGetSearchLike(
    params: ProductModel.ApiGetSearchLikeModel,
    option: any = {}
  ): Promise<any> {
    return await axios({
      method: 'get',
      url: '/api_v2/search_like',
      params,
      ...option,
    });
  }

  // 专题商品列表
  public static async apiGetSpecialGoods(
    params: ProductModel.ApiGetSpecialGoodsModel,
    option: any = {}
  ): Promise<any> {
    return await axios({
      method: 'get',
      url: '/api_v2/special_goods',
      params,
      ...option,
    });
  }

  // 特惠专区
  public static async apiGetSpecialIndex(params: any = {}, option: any = {}): Promise<any> {
    return await axios({
      method: 'get',
      url: '/api_v2/special_index',
      params,
      ...option,
    });
  }

  // 爆品秒杀
  public static async apiGetSeckillIndex(params: any = {}, option: any = {}): Promise<any> {
    return await axios({
      method: 'get',
      url: '/api_v2/seckill_goods',
      params,
      ...option,
    });
  }
}
