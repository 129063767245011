
  import { defineComponent, reactive } from 'vue';

  interface CompanyInfoItem {
    link?: string;
    name?: string;
  }

  interface CertListItem {
    id?: number;
    name?: string;
    link?: string;
  }

  export default defineComponent({
    props: {
      mod: {
        type: String,
        default: 'white',
      },
    },
    setup() {
      //证件
      const certList = reactive<CertListItem[]>([
        {
          name: '药品经营许可证：赣AA7970026',
          id: 1,
          link: '/white-list/aptitude?n=1',
        },
        {
          name: '食品经营许可证：JY13607010040859',
          id: 2,
          link: '/white-list/aptitude?n=2',
        },
        {
          name: '第二类医疗器械经营备案凭证：赣市食药监械经营备20150323号  ',
          id: 3,
          link: '/white-list/aptitude?n=3',
        },
        {
          name: '互联网药品信息服务资格证书：赣B202107090267',
          id: 4,
          link: '/white-list/aptitude?n=4',
        },
      ]);
      //备案信息
      const companyInfo = reactive<CompanyInfoItem[]>([
        {
          name: '江西青峰医药贸易有限公司',
        },
        {
          name: '©版权所有备案号：赣ICP备16001060号-3',
          link: 'http://beian.miit.gov.cn/',
        },
        {
          name: '赣公网安备： 36070002000212号',
          link: 'http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=36070002000212',
        },
        {
          name: '统一社会信用代码：91360703690989564U',
        },
      ]);
      function go(item: any, index: number) {
        if (index === 3) {
          window.open(location.origin + '/#/white-list/aptitude?n=5');
        } else if (index === 1 || index === 2) {
          window.open(item.link);
        }
      }
      return {
        certList,
        companyInfo,
        go,
      };
    },
  });
