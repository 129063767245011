import axios, { AxiosRequestConfig } from 'axios';
import qs from 'qs';
import { ElMessage, ElLoading } from 'element-plus';
import Store from '@/store/index';
import Utils from '@/utils';
import router from '@/router/index';

interface ExtAxiosRequestConfig extends AxiosRequestConfig {
  useLoading?: boolean;
  loadingText?: string;
}

let loadingInstance: any;

//请求地址
axios.defaults.baseURL = process.env.VUE_APP_GLOB_API_URL;
//超时时间
axios.defaults.timeout = 60000;
//跨域请求时是否需要使用凭证
axios.defaults.withCredentials = false;
//内容类型
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
// 允许跨域
// axios.defaults.headers.post["Access-Control-Allow-Origin-Type"] = "*";

//token
axios.defaults.headers.post['token'] = Utils.safeData(Store, 'state.token', '');

//请求拦截器
axios.interceptors.request.use(
  (config: ExtAxiosRequestConfig) => {
    if (config.method === 'post' || config.method === 'put' || config.method === 'delete') {
      // qs序列化
      config.data = qs.stringify(config.data);
    }
    config.headers && (config.headers['token'] = Utils.safeData(Store, 'state.token', ''));
    config.useLoading === undefined && (config.useLoading = true);
    if (config.useLoading) {
      loadingInstance = ElLoading.service({
        lock: true,
        text: config.loadingText || '获取数据中...',
      });
    }
    return config;
  },
  (error) => {
    ElMessage.error(error.data.error.message);
    return Promise.reject(error.data.error.message);
  }
);
//响应拦截器
axios.interceptors.response.use(
  (response) => {
    if (loadingInstance) {
      loadingInstance.close();
    }

    //处理http状态码
    const statuStr: string = response.status + '';
    //截取http状态码第一个字符
    const httpStatus = statuStr.substr(0, 1);
    switch (httpStatus) {
      case '4':
        ElMessage.error('请求错误');
        break;
      case '5':
        ElMessage.error('服务器响应错误');
        break;
    }

    //处理业务状态码
    const result = response.data;
    if (result.code == 2333) {
      //未登录 token过期
      Store.commit('logout');
      return Promise.reject(result);
    } else if (result.code == 2001 || result.code == 2002) {
      //业务错误
      ElMessage.error(result.msg);
      return Promise.reject(result);
    } else if (result.code === 2008) {
      router.replace('/register/step-3?useApi2=1');
      return Promise.reject(result);
    } else if (result.code === 2011) {
			/// 停服通知
      router.replace('/notice');
      return Promise.reject(result);
    } else {
      //正常返回
      return Promise.resolve(result);
    }
  },
  (error) => {
    if (loadingInstance) {
      loadingInstance.close();
    }
    if (axios.isCancel(error)) {
      console.log('repeated request: ' + error.message);
    } else {
      // 错误抛到业务代码
      ElMessage.error('请求超时或网络异常');
    }
    return Promise.reject(error);
  }
);
export default axios;
