
  import { defineComponent, reactive } from 'vue';
  import { ArrowRight } from '@element-plus/icons-vue';
  export default defineComponent({
    components: { ArrowRight },
    props: {
      mod: String,
    },
    setup() {
      //按钮
      const codeBtn = reactive<any[]>([
        {
          label: 'APP下载',
          img: require('../assets/image/common/qrcode-app.png'),
        },
        // {
        //   label: '小程序',
        //   img: require('../assets/image/common/qrcode-mp.png'),
        // },
        {
          label: '官方微信',
          img: require('../assets/image/common/qrcode-h5.png'),
        },
      ]);
      return {
        codeBtn,
      };
    },
  });
