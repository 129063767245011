// import Router from "@/router/index";
import { ElMessage, ElMessageBox } from 'element-plus';
import { saveAs } from 'file-saver';
import { ConfirmPopModel } from '@/@types';
export default class Utils {
  /**
   * 判断手机号码是否正确
   * @param phone
   * @returns
   */
  public static checkPhone(phone: string): boolean {
    if (!/^1[3456789]\d{9}$/.test(phone)) {
      return false;
    }
    return true;
  }

  /**
   * 秒转换为天时分秒时间格式
   * @param s 秒
   * @returns
   */
  public static timeFormat(s: number): string {
    const day: number = Math.floor(s / (60 * 60 * 24));
    const hours: number = Math.floor(s / (60 * 60)) - day * 24;
    const minutes: number = Math.floor(s / 60) - day * 24 * 60 - hours * 60;
    const seconds: number = Math.floor(s) - day * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60;
    let dayFormat: string = '';
    if (day <= 0) {
      dayFormat = '00';
    } else if (day > 0 && day < 10) {
      dayFormat = '0' + day;
    } else {
      dayFormat = day + '';
    }

    return (
      dayFormat +
      ':' +
      (hours < 10 ? '0' + hours : hours) +
      ':' +
      (minutes < 10 ? '0' + minutes : minutes) +
      ':' +
      (seconds < 10 ? '0' + seconds : seconds)
    );
  }

  /**
   * 页面跳转
   * @param  {string} argPath  [路径] 无数据时返回
   * @param  {string} argType [跳转方式 push、replace、go] = "push"
   */
  public static toPage(argPath: string, argType: string): void {
    console.log(argPath);
    console.log(argType);
    // if (!argPath) {
    //     Router.go(-1);
    // } else if (argPath[0] === "/") {
    //     Router[argType](argPath);
    // } else {
    //     Router[argType](location.hash.replace("#", "") + "/" + argPath);
    // }
  }

  /**
   * @function
   * @description 数据安全访问
   * @param  {object|Array} argData  [原始数据]
   * @param  {string} argCheck [要返回的数据，用'.'连接，数组用'.+数字表示']
   * @param  {*} argValue [如果数据有误，返回的值，选填]
   * @param  {Boolean} argSetValueForce [是否强制赋值argValue]
   * @returns {any}
   */
  public static safeData(
    argData: any,
    argCheck: string,
    argValue?: any,
    argSetValueForce?: boolean
  ): any {
    const temKey: string[] = argCheck.toString().split('.');
    const temLen: number = temKey.length;
    if (!argData) {
      return argValue;
    }
    if (temLen > 1) {
      for (let i = 0; i < temLen - 1; i++) {
        if (typeof argData[temKey[i]] !== 'object') {
          return argValue;
        }
        argData = argData[temKey[i]] || {};
      }
    }
    if (argSetValueForce) {
      argData[temKey[temLen - 1]] = argValue;
    }
    if (argValue === null) {
      let re = argData[temKey[temLen - 1]];
      if (re !== 0 && !re) {
        re = null;
      } else {
        re = +re;
      }
      return re;
    }
    if (typeof argValue === 'undefined') {
      return argData[temKey[temLen - 1]];
    }
    return argData[temKey[temLen - 1]] || argValue;
  }

  /**
   * @description 日期格式化显示
   * @function
   * @param  {any} date 时间对象\时间戳，默认当前时间
   * @param  {string} fmt 格式化符串，默认'YYYY-MM-DD HH:mm:ss' E为星期数，EEE:星期一 q为季度，S为毫秒数
   * @param  {string} emptyTip date为false时，默认''
   * @returns {string}
   */
  public static formatTime(
    date: Date | number | string = +new Date(),
    fmt = 'YYYY-MM-DD HH:mm:ss',
    emptyTip = ''
  ): string {
    if (!date && date !== 0) {
      return emptyTip;
    }
    if (typeof date === 'number') {
      date = '' + date;
      if (date.length === 10) {
        date += '000';
      }
    }
    date = new Date(+date);
    const o: any = {
      'M+': date.getMonth() + 1,
      'D+': date.getDate(),
      'h+': date.getHours() % 12 === 0 ? 12 : date.getHours() % 12,
      'H+': date.getHours(),
      'm+': date.getMinutes(),
      's+': date.getSeconds(),
      'q+': Math.floor((date.getMonth() + 3) / 3),
      S: date.getMilliseconds(),
    };
    const week: any = {
      0: '\u65e5',
      1: '\u4e00',
      2: '\u4e8c',
      3: '\u4e09',
      4: '\u56db',
      5: '\u4e94',
      6: '\u516d',
    };
    if (/(Y+)/.test(fmt)) {
      fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
    }
    if (/(E+)/.test(fmt)) {
      fmt = fmt.replace(
        RegExp.$1,
        (RegExp.$1.length > 1 ? (RegExp.$1.length > 2 ? '\u661f\u671f' : '\u5468') : '') +
          week[date.getDay() + '']
      );
    }
    for (const k in o) {
      if (new RegExp('(' + k + ')').test(fmt)) {
        fmt = fmt.replace(
          RegExp.$1,
          RegExp.$1.length === 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length)
        );
      }
    }
    return fmt;
  }

  /**
   * 成功信息提示
   * @param  {string} msg  提示信息
   */
  public static msgSuc(msg: string): void {
    ElMessage({
      message: msg,
      type: 'success',
    });
  }

  /**
   * 警告信息提示
   * @param  {string} msg  提示信息
   */
  public static msgWarning(msg: string): void {
    ElMessage({
      message: msg,
      type: 'warning',
    });
  }

  /**
   * 失败信息提示
   * @param  {string} msg  提示信息
   */
  public static msgError(msg: string): void {
    ElMessage.error(msg);
  }

  /**
   * 普通信息提示
   * @param  {string} msg  提示信息
   */
  public static msgInfo(msg: string): void {
    ElMessage(msg);
  }

  /**
   * 确认弹窗
   * @param  {any} options 确认弹窗
   */
  public static confirm(options: ConfirmPopModel): Promise<any> {
    options = {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      title: '提示',
      type: 'warning',
      ...options,
    };
    return new Promise((resolve, reject) => {
      ElMessageBox.confirm('', '', options as any)
        .then(() => {
          resolve('ok');
        })
        .catch(() => {
          reject('cancel');
        });
    });
  }

  /**
   * @function
   * @description 获取url参数
   * @param  {string} argName  [参数名] 无数据时返回
   * @param  {string} argUrl [url]
   */
  public static urlParam(argName: string, argUrl: string = window.location.href): string {
    const result = argUrl.match(new RegExp('[?&]' + argName + '=([^&]+)', 'i'));
    if (!result) {
      return '';
    }
    return decodeURIComponent(result[1]) || '';
  }

  /**
   * 下载zip文件
   * @param  {any} res  文件信息
   * @param  {string} name  文件名
   */
  public static downZIP(res: any, name: string): void {
    const blob = new Blob([res], { type: 'application/zip' });
    saveAs(blob, `${name}.zip`);
  }
  /**
   * 分割数组
   * @param  {Array} arr   是你要分割的数组
   * @param {number} number  是以几个为一组
   */
  public static spArr(arr: [], num: number): any {
    const newArr: any = [];
    for (let i = 0; i < arr.length; ) {
      newArr.push(arr.slice(i, (i += num)));
    }
    return newArr;
  }

  /**
   * @des 防抖 ，多次只执行最后一次
   * @param func 需要包装的函数
   * @param delay 延迟时间，单位ms
   * @param immediate 是否默认执行一次(第一次不延迟)
   */
  public static debounce(func: Function, delay: number, immediate = false) {
    let timer: number | null = null;
    return (...args: any) => {
      if (timer) clearInterval(timer);
      if (immediate) {
        if (!timer) func.apply(this, args);
        timer = window.setTimeout(() => {
          timer = null;
        }, delay);
      } else {
        timer = window.setTimeout(() => {
          func.apply(this, args);
        }, delay);
      }
    };
  }

  /**
   * @des 节流，多次都会执行，只是间隔一段时间再执行
   * @param func 需要包装的函数
   * @param delay 延迟时间，单位ms
   * @param immediate 是否默认执行一次(第一次不延迟)
   */
  public static throttle(func: Function, delay: number, immediate = false) {
    if (immediate) {
      let prevTime = 0;
      return (...args: any) => {
        const nowTime = Date.now();
        if (nowTime - prevTime >= delay) {
          func.apply(this, args);
          prevTime = nowTime;
        }
      };
    } else {
      let timer: any;
      return (...args: any) => {
        if (!timer) {
          func.apply(this, args);
          timer = setTimeout(() => {
            if (timer) clearInterval(timer);
            timer = null;
          }, delay);
        }
      };
    }
  }
}
