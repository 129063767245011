import PageLayout from '@/layouts/PageLayout.vue';
const feedback = {
  path: 'feedback',
  redirect: '/feedback',
  name: '意见反馈',
  component: PageLayout,
  meta: { name: '意见反馈' },
  children: [
    {
      path: '',
      name: '提交反馈',
      component: () => import(/* webpackChunkName: "feedback" */ '../views/feedback/Feedback.vue'),
      meta: { name: '提交反馈' },
    },
    {
      path: 'history',
      name: '历史记录',
      component: () => import(/* webpackChunkName: "feedback" */ '../views/feedback/History.vue'),
      meta: { name: '历史记录', background: '#fff' },
    },
  ],
};
export default feedback;
